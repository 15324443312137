import * as React from "react";
import LinkJuiceCategorySection from "./LinkJuiceCategorySection";
import { LinkJuiceProps } from "./typings";
import { InsideTabGroupContext } from "components/shared/InsideTabGroupContext/InsideTabGroupContext";
import { EGDSHeading } from "@egds/react-core/text";
import { withStores } from "src/stores";
import { LinkJuiceFlexModuleResult } from "typings/microserviceModels/link-juice-flex-module";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSExpandoPeek } from "@egds/react-core/expando";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

const expandoReferrerId = "LatestTrends.action.Expando";
const expandoDescription = "LatestTrends.action.SeeMore_SeeLess";

const LinkJuice = (props: LinkJuiceProps) => {
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const [isVisible, setIsVisible] = React.useState(false);
  const handleClick = React.useCallback(() => {
    track(
      `${expandoReferrerId}.${isVisible ? "closed" : "opened"}`,
      `${expandoDescription} ${isVisible ? "collapsed" : "expanded"}`
    );
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible, track]);

  const insideTabGroup = React.useContext(InsideTabGroupContext);
  const { templateComponent, flexModuleModelStore, context } = props;

  if (!templateComponent || !flexModuleModelStore) {
    return null;
  }

  const { metadata, config } = templateComponent;
  const { id } = metadata;
  const { fmTitleId } = config;
  const fmId = getFmId(templateComponent);
  const { hideTitle, semiCollapsible, displayThreshold } = templateComponent.config;

  const model = flexModuleModelStore.getModel(id) as LinkJuiceFlexModuleResult | null;
  if (!model) {
    return null;
  }

  const { title, categories, collapsed, displayColumns } = model;

  if (!(title && categories && categories.length)) {
    return null;
  }

  const categoriesMapping = (
    <>
      {categories.map(
        (category) =>
          category.name && (
            <LinkJuiceCategorySection
              key={category.name}
              category={category}
              collapsed={collapsed || false}
              displayColumns={displayColumns}
            />
          )
      )}
    </>
  );

  return (
    <LazyLoad context={context}>
      <EGDSSpacing padding={insideTabGroup ? { blockstart: "unset" } : { blockstart: "three" }}>
        <div className="LinkJuice" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
          {!hideTitle && !insideTabGroup && (
            <EGDSSpacing padding={{ block: "three" }}>
              <div>
                <EGDSHeading tag="h2" size={4}>
                  {title}
                </EGDSHeading>
              </div>
            </EGDSSpacing>
          )}
          {semiCollapsible ? (
            <EGDSExpandoPeek
              isVisible={isVisible}
              handleClick={handleClick}
              items={displayThreshold}
              hideScrim={true}
              expandLabel={formatText("propertyfilter.seeMore")}
              collapseLabel={formatText("propertyfilter.seeLess")}
              expandA11yLabel={formatText("propertyfilter.seeMore")}
              collapseA11yLabel={formatText("propertyfilter.seeLess")}
              whitespace={false}
            >
              {categoriesMapping}
            </EGDSExpandoPeek>
          ) : (
            categoriesMapping
          )}
        </div>
      </EGDSSpacing>
    </LazyLoad>
  );
};

export default withStores("flexModuleModelStore")(LinkJuice);
