import * as React from "react";

import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSLayoutGrid, EGDSLayoutGridColumnRow } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

import Link from "./components/Link";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { LinkJuiceCategorySectionProps } from "./typings";

const LinkJuiceCategorySection = (props: LinkJuiceCategorySectionProps) => {
  const { category, collapsed, displayColumns } = props;
  const { name, links } = category;
  if (!(name && links && links.length)) {
    return null;
  }

  const DEFAULT_MOBILE_DISPLAY_COLUMNS = 1;
  const columns = {
    small: DEFAULT_MOBILE_DISPLAY_COLUMNS as EGDSLayoutGridColumnRow,
    large: Number(displayColumns) as EGDSLayoutGridColumnRow,
  };

  const [isExpanded, setIsExpanded] = React.useState(!collapsed);

  const toggleExpansion = (): void => {
    setIsExpanded(!isExpanded);
  };
  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleExpansion();
    }
  };

  const keyHelper = new ItemKeyHelper("LinkJuiceLinks");

  return (
    <EGDSSpacing margin="one">
      <EGDSCard>
        <div
          className="CallToAction"
          tabIndex={0}
          role="button"
          aria-expanded={isExpanded}
          onClick={toggleExpansion}
          data-testid="expando-toggle"
          onKeyDown={expandoKeyboardPress}
        >
          <EGDSCardContentSection padded>
            <EGDSLayoutFlex>
              <EGDSLayoutFlexItem grow={1}>
                <EGDSText size={400} weight="bold" theme="default">
                  {name}
                </EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                <EGDSIcon name={isExpanded ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>
        </div>
        <EGDSCardContentSection
          padded={["blockend", "inlinestart", "inlineend"]}
          className={`${isExpanded ? "" : "is-hidden"}`}
        >
          <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
            <div>
              <EGDSLayoutGrid columns={columns} space="one">
                {links.map((link) => (
                  <Link key={keyHelper.next()} link={link} />
                ))}
              </EGDSLayoutGrid>
            </div>
          </EGDSSpacing>
        </EGDSCardContentSection>
      </EGDSCard>
    </EGDSSpacing>
  );
};

export default LinkJuiceCategorySection;
