import * as React from "react";

import { EGDSLink } from "@egds/react-core/link";
import { EGDSText } from "@egds/react-core/text";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { LinkProps } from "../typings";

const Link = (props: LinkProps) => {
  const { link } = props;
  const { text, url } = link;

  if (!(text && url)) {
    return null;
  }

  return (
    <EGDSText inline overflow="truncate">
      <EGDSLink>
        <TrackedLink moduleName="link-juice" href={url} linkName={text}>
          {text}
        </TrackedLink>
      </EGDSLink>
    </EGDSText>
  );
};

export default Link;
